<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-row>
          <b-col class="text-left">
            <b-button
              :to="{
                name: 'apps-bookings-pastbookings-list',
              }"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-auto"
              type="submit"
              size="sm"
            >
              {{ $t("Back to Overview") }}
            </b-button>
          </b-col>

        </b-row>

        <hr />

        <b-form
          class="p-2"
          @submit.stop.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div class="mb-2">
            <b-form-checkbox
              v-model="currentItem.status"
              value="1"
              name="status"
              class="custom-control-success"
            >
              {{ $t("Active") }}
            </b-form-checkbox>
          </div>

          <hr />

          <b-row>
            <col md="12" xl="12" class="mb-1 mt-1 ml-1" />
            <b-form-group :label="$t('Choose a Weekday')" label-for="Weekday">
              <b-form-select v-model="currentItem.weekday" :options="options" />
            </b-form-group>
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <validation-provider
                  #default="validationContext"
                  :name="$t('Starttime')"
                  rules="required"
              >
                <b-form-group :label="$t('Start Time')">
                  <cleave
                      id="starttime"
                      v-model="currentItem.starttime"
                      :state="getValidationState(validationContext)"
                      :name="$t('Starttime')"
                      class="form-control"
                      :raw="false"
                      :options="timeOption.starttime"
                      placeholder="hh:mm"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <validation-provider
                  #default="validationContext"
                  :name="$t('endtime')"
                  rules="required"
              >
                <b-form-group :label="$t('End Time')">
                  <cleave
                      id="endtime"
                      v-model="currentItem.endtime"
                      :name="$t('endtime')"
                      :state="getValidationState(validationContext)"
                      class="form-control"
                      :raw="false"
                      :options="timeOption.endtime"
                      placeholder="hh:mm"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-button
                class="mt-2"
                variant="success"
                type="submit"
                @click.prevent="onSubmit"
              >
                {{ $t("Save") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </component>
</template>


<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormTimepicker,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Cleave from "vue-cleave-component";
import { ref, onUnmounted, mounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { required, alphaNum, email } from "@validations";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BFormTimepicker,
    BFormSelect,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      selected: null,
      options: [
        { value: null, text: this.$i18n.t("Please select a Day") },
        { value: 1, text: this.$i18n.t("Sunday") },
        { value: 2, text: this.$i18n.t("Monday") },
        { value: 3, text: this.$i18n.t("Tuesday") },
        { value: 4, text: this.$i18n.t("Wednesday") },
        { value: 5, text: this.$i18n.t("Thursday") },
        { value: 6, text: this.$i18n.t("Friday") },
        { value: 7, text: this.$i18n.t("Saturday") },
      ],

      currentItem: {
        status: "",
        weekday: "",
        starttime: "",
        endtime: "",
      },

      timeOption: {
        starttime: {
          time: true,
          timePattern: ["h", "m"],
        },
        endtime: {
          time: true,
          timePattern: ["h", "m"],
        },
      },

      loaded: true,
    };
  },

  setup(props) {
    const expertData = {
      status: "",
      weekday: "",
      starttime: "",
      endtime: "",
    };

    const toast = useToast();
  },

  mounted() {
    this.helperGetItem(`/bookings/${this.$route.params.id}`);
  },

  methods: {
    updateItem() {
      this.helperUpdateItem(
        `/bookings/${this.$route.params.id}`,
        this.currentItem
      );
    },

    deleteItem() {
      this.helperDeleteItem(
        `/bookings/${this.$route.params.id}`,
        null,
        "apps-bookings-list"
      );
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    resetForm() {},

    onSubmit() {
      this.updateItem();
    },
  },
};
</script>
